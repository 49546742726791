<template>
  <div
    class="responsible"
    :style="`background: url('${useRuntimeConfig().public.marcovasco.twicpicsPlanetveoUrl}${image?.data.attributes.url}?twic=v1/output=webp/resize=${!breakpoints.isMobile ? 1920 : 370}x410')`"
  >
    <div class="inner">
      <div class="infos">
        <span class="title">
          {{ title }}
        </span>
        <p>
          {{ description }}
        </p>
        <NuxtLink v-if="cta" :to="cta.url">
          <div class="more">
            {{ cta.label }}
          </div>
        </NuxtLink>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { Media } from '@/lib/strapi-types/Media';
import { Cta } from '@/lib/strapi-types/components/Cta';

defineProps<{
  title: string;
  description?: string;
  cta?: Cta;
  image?: { data: Media };
}>();

const breakpoints = useBreakpoints();
</script>

<style lang="scss" scoped>
@use '$/button.scss';
@use '$/breakpoints.scss';

.responsible {
  width: 100dvw;
  height: 410px;
  background-position: center;
  background-size: cover;

  p {
    font-size: 18px;
    font-weight: 400;
  }

  .inner {
    position: relative;

    display: grid;
    grid-template-columns: 1fr 1fr;

    width: 100%;
    height: 100%;

    background: linear-gradient(90deg, rgb(0 0 0 / 60%) 40.87%, rgb(0 0 0 / 0%) 87.81%);

    @include breakpoints.mobile() {
      display: flex;
      justify-content: center;
      background: rgb(0 0 0 / 60%);
    }

    .infos {
      flex-direction: column;
      justify-content: space-between;

      width: 502px;
      margin: 60px 0 60px 200px;

      color: white;

      @include breakpoints.mobile() {
        align-items: center;
        justify-content: space-evenly;

        width: 100%;
        margin: 0 16px;

        text-align: center;
      }

      .title {
        font-size: 48px;
        font-weight: 800;

        @include breakpoints.mobile() {
          font-size: 30px;
        }
      }

      .more {
        @include button.outlined(white);

        width: fit-content;
      }
    }
  }
}
</style>
